<template>
  <div class="flex flex-col gap-8">
    <div
      class="mr-auto flex items-center gap-2 cursor-pointer"
      @click="router.back"
    >
      <TrailIconButton icon="local:arrow-left" variant="tertiary" />

      <p class="text-trail-title4 text-trail-text-title-normal">
        {{ $t('checkoutBackToCart') }}
      </p>
    </div>

    <div class="flex gap-4 items-center justify-between">
      <h3 class="text-trail-title1 text-trail-text-title-normal">
        {{ $t('checkoutTitle') }}
      </h3>

      <div class="flex flex-col justify-center items-end">
        <TrailButton
          :loading="placeOrderButtonLoading"
          :disabled="placeOrderDisabled"
          class="hidden md:block"
          @click="placeOrder"
        >
          {{ $t('checkoutPlaceOrder') }}
        </TrailButton>

        <Transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <span
            v-if="creditCardAmountCheck"
            class="trail text-trail-caption text-trail-surface-primary-negative"
          >
            {{ creditCardAmountCheck }}
          </span>
        </Transition>
      </div>
    </div>

    <div class="flex flex-col gap-6">
      <div
        class="fixed left-0 bottom-0 w-full bg-trail-background-secondary rounded-t-2xl shadow-trail md:static md:grid md:shadow-none md:bg-transparent md:grid-cols-2 md:gap-6"
      >
        <ShoppingCartSummary
          :quantity="quantity"
          :calculation="orderCalculation ?? undefined"
          :calculation-loading="
            buyEsimStore.destinationsLoading || orderCalculationLoading
          "
          :calculation-error="!!orderCalculationError"
          class="p-4 justify-end"
        />

        <TrailDivider class="md:hidden mx-4" />

        <div
          class="p-4 bg-trail-background-secondary rounded-2xl flex flex-col gap-4"
        >
          <ChoosePaymentMethod
            v-model:payment-method="paymentMethod"
            v-model:have-insufficient-funds="haveInsufficientFunds"
            :calculation="orderCalculation ?? undefined"
          />

          <TrailDivider class="md:hidden" />

          <TrailButton
            :loading="placeOrderButtonLoading"
            :disabled="!paymentMethod || !shoppingCart?.length"
            class="md:hidden"
            @click="placeOrder"
          >
            {{ $t('checkoutPlaceOrder') }}
          </TrailButton>

          <div class="mt-auto text-trail-body3 text-trail-text-body-normal">
            {{ $t('buyEsimTermsAndCondition1') }}

            <a
              href="https://www.airalo.com/more-info/terms-conditions"
              target="_blank"
              rel="noopener noreferrer"
              class="text-trail-body-link3 underline"
            >
              {{ $t('buyEsimTermsAndCondition2') }}
            </a>

            {{ $t('buyEsimTermsAndCondition3') }}
            <a
              href="https://www.airalo.com/more-info/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              class="text-trail-body-link3 underline"
            >
              {{ $t('buyEsimTermsAndCondition4') }} </a
            >.
          </div>
        </div>
      </div>

      <div
        class="hidden bg-trail-background-secondary rounded-2xl flex-col gap-4 md:flex"
      >
        <div class="px-4 pt-4 flex items-center justify-between gap-4">
          <p class="text-trail-title3 text-trail-text-title-normal">
            {{ $t('shoppingCartTitle') }}
          </p>

          <BaseListItem :label="$t('shoppingCartItems')">
            {{ $t('shoppingCartQuantity', quantity) }}
          </BaseListItem>
        </div>

        <TrailBanner
          v-if="quantity >= MAXIMUM_ESIM_QUANTITY"
          show-icon
          type="negative"
          class="mx-4"
        >
          {{ $t('buyEsimMaximumEsimQuantity') }}
        </TrailBanner>

        <TrailDataTable
          :columns="columns"
          :rows="shoppingCart"
          :no-data-description="$t('shoppingCartEmpty')"
          :loading="buyEsimStore.destinationsLoading"
          class="-mt-4 text-trail-body3 text-trail-text-body-normal"
        >
          <template #sim="{ row }">
            <div class="flex gap-4 items-center">
              <img
                :src="row.countryImage"
                :alt="row.operator.title"
                class="w-7"
              />

              <button
                :class="[
                  'text-trail-body-link3',
                  isModalTopup ? 'cursor-auto' : 'underline',
                ]"
                @click="openPackageDetail(row)"
              >
                {{ row.operator.title }}
              </button>
            </div>
          </template>

          <template #sim-loader>
            <div class="flex items-center gap-4">
              <RectangleSkeleton class="w-7 h-5" />

              <RectangleSkeleton class="w-20 h-5 rounded-full" />
            </div>
          </template>

          <template #coverage="{ row }">
            {{ row.country }}
          </template>

          <template #coverage-loader>
            <RectangleSkeleton class="w-24 h-5 rounded-full" />
          </template>

          <template #package="{ row }">
            <div class="flex gap-2">
              <template v-for="({ value, unit }, index) in getPackageInfo(row)">
                <TrailDivider
                  v-if="index > 0"
                  :key="index"
                  orientation="vertical"
                />

                {{ value }} {{ unit }}
              </template>
            </div>
          </template>

          <template #package-loader>
            <RectangleSkeleton class="w-10 h-5 rounded-full" />
          </template>

          <template #validity="{ row }">
            {{ $t('checkoutValidityPeriod', row.day) }}
          </template>

          <template #validity-loader>
            <RectangleSkeleton class="w-16 h-5 rounded-full" />
          </template>

          <template #price="{ row }">
            <div v-if="authStore.hasResellerStream">
              <span class="text-trail-title5 text-trail-text-title-highlight">
                {{ formatAmount(row.net_price) }}
              </span>

              <span class="text-trail-body3 text-trail-text-body-normal">
                ({{ $t('buyEsimRetailPrice') }}: {{ formatAmount(row.price) }})
              </span>
            </div>

            <div v-else class="text-trail-title5 text-trail-text-body-normal">
              {{ formatAmount(row.price) }}
            </div>
          </template>

          <template #price-loader>
            <RectangleSkeleton class="w-24 h-5 rounded-full" />
          </template>

          <template #quantity="{ row }">
            <ShoppingCartSelectQuantity
              :quantity="quantity"
              :item-quantity="row.quantity"
              align="end"
              @update:item-quantity="handleUpdateQuantity(row.slug, $event)"
            />
          </template>

          <template #quantity-loader>
            <RectangleSkeleton class="w-16 h-10 rounded-lg" />
          </template>
        </TrailDataTable>
      </div>
    </div>

    <ShoppingCartTable
      :shopping-cart="shoppingCart"
      :quantity="quantity"
      class="-mt-12 bg-trail-background-secondary pt-4 mb-48 rounded-2xl md:hidden"
      @update:quantity="handleUpdateQuantity"
    />

    <PackageDetailsModal
      :is-opened="showPackageDetail"
      :sim="packageDetailData"
      :available-packages="
        packageDetailData?.packages?.filter((item) => item.type === 'topup') ||
        []
      "
      :global-networks="globalNetworks"
      @clickClose="showPackageDetail = false"
    />

    <PaymentSuccessModal
      :open="showOrderSuccessfulModal"
      :order-code="orderCode"
      @go-to-esims-list="router.push('/manage-esims')"
      @go-to-order-detail="router.push(`/orders/${orderCode}`)"
      @update:model-value="handlePaymentSuccessModalClose"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, watch, onMounted, ref, inject } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import type { Axios } from 'axios'
import { definePage, useRouter, onBeforeRouteLeave } from 'vue-router/auto'
import type { Stripe } from '@stripe/stripe-js'
import { useLocalStorage } from '@vueuse/core'
import { useApiData } from '@/composables/useApiData'
import type {
  OrderCalculation,
  PaymentMethod,
  RecentlyVisitedItem,
  ShoppingCartItem,
} from '@/models/orderModels'
import { useAuthStore } from '@/stores/auth'
import { generateNetworks } from '@/utils/generateNetworks'
import { useBuyEsimStore } from '@/stores/buy-esim.store'
import { MAXIMUM_ESIM_QUANTITY } from '@/constant/shoppingBag'
import { formatAmount } from '@/utils/formatAmount'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import { z } from 'zod'
import { groupBy, get } from 'lodash'

definePage({
  name: 'esim-store-checkout',
  meta: { requiresAuth: true, requiredPermissions: ['buy-esims'] },
})
const { t } = useI18n()

const router = useRouter()
const toast = useTrailToast()

const buyEsimStore = useBuyEsimStore()
const authStore = useAuthStore()
const { shoppingCart } = storeToRefs(buyEsimStore)

const paymentMethod = ref<PaymentMethod>()

const quantity = computed(() =>
  shoppingCart.value.reduce((a, b) => a + b.quantity, 0)
)

const columns = computed(() => [
  {
    key: 'sim',
    name: t('checkoutColumnSim'),
  },
  {
    key: 'coverage',
    name: t('checkoutColumnCoverage'),
  },
  {
    key: 'package',
    name: t('checkoutColumnPackage'),
  },
  {
    key: 'validity',
    name: t('checkoutColumnValidity'),
    customHeaderClass: 'justify-end',
    customClass: 'flex justify-end',
  },
  {
    key: 'price',
    name: t('checkoutColumnPrice'),
    customHeaderClass: 'justify-end',
    customClass: 'flex justify-end',
  },
  {
    key: 'quantity',
    name: t('checkoutColumnQuantity'),
    customHeaderClass: 'justify-end',
    customClass: 'flex justify-end',
  },
])

const showPackageDetail = ref(false)
const packageDetailData = ref<ShoppingCartItem | null>(null)
const globalNetworks = ref(null)

const orderCalculationAbortController = ref<AbortController>()
const {
  data: orderCalculation,
  loading: orderCalculationLoading,
  error: orderCalculationError,
  createData: fetchOrderCalculation,
} = useApiData<OrderCalculation>('/store/v1/orders/calculate', {
  immediate: false,
  config: () => ({
    signal: orderCalculationAbortController.value?.signal,
  }),
  refetch: {
    watch: () => authStore.userCurrency,
    callback: () => handleFetchOrderCalculation(),
  },
})

const isModalTopup = computed(() => {
  return buyEsimStore?.shoppingBagOptions?.isTopup
})

const handleFetchOrderCalculation = () => {
  if (orderCalculationAbortController.value) {
    orderCalculationAbortController.value.abort()
  }

  if (shoppingCart.value.length) {
    fetchOrderCalculation({
      sims: shoppingCart.value.map(({ slug, quantity }) => ({
        package_id: slug,
        quantity,
      })),
    })
  } else {
    orderCalculation.value = null
  }
}

const removeFromShoppingBag = (packageName: string) => {
  if (isModalTopup.value) {
    buyEsimStore.topupShoppingBag = buyEsimStore.topupShoppingBag.filter(
      (shoppingItem) => shoppingItem.slug !== packageName
    )
  } else {
    buyEsimStore.removePackage(packageName)
  }
}

const handleUpdateQuantity = (slug: string, quantity: number) => {
  if (quantity === 0) {
    removeFromShoppingBag(slug)
  } else if (isModalTopup.value) {
    const shoppingItem = buyEsimStore.topupShoppingBag.find(
      (bag) => bag.slug === slug
    )
    if (shoppingItem) {
      shoppingItem.quantity = quantity
    }
  } else {
    buyEsimStore.changePackagesAmount(slug, quantity)
  }
}

const getPackageInfo = (row: ShoppingCartItem) => {
  return [
    { value: row.data, unit: '' },
    { value: row.voice, unit: 'min' },
    { value: row.text, unit: 'SMS' },
  ].filter(({ value }) => !!value)
}

const openPackageDetail = (item: ShoppingCartItem) => {
  if (isModalTopup.value) {
    return
  }

  showPackageDetail.value = true
  packageDetailData.value = item
  generateNetworks(packageDetailData.value, globalNetworks)
}

const showOrderSuccessfulModal = ref(false)
const orderCode = ref('')
const placeOrderButtonLoading = ref(false)
const axios = inject<Axios>('axios')
const stripe = inject<Stripe>('stripe')

const recentlyVisited = useLocalStorage<RecentlyVisitedItem[]>(
  'searchRecentlyVisited',
  []
)

const getDestinationsForShoppingCart = () => {
  return shoppingCart.value
    .map((item) => {
      const destinations = [
        ...buyEsimStore.destinations!.countries.map((country) => ({
          ...country,
          type: 'local',
        })),
        ...buyEsimStore.destinations!.regions.map((country) => ({
          ...country,
          type: 'region',
        })),
        ...buyEsimStore.destinations!.globals.map((country) => ({
          ...country,
          type: 'global',
        })),
      ]

      return destinations.find((destination) =>
        destination.operators.some(({ packages }) =>
          packages.some(({ slug }) => slug === item.slug)
        )
      )
    })
    .filter((destination) => !!destination)
}

const placeOrder = async () => {
  if (paymentMethod.value === undefined) {
    return
  }
  placeOrderButtonLoading.value = true

  try {
    const response = await axios?.post('/store/v1/orders', {
      [isModalTopup.value ? 'topups' : 'sims']: shoppingCart.value.map(
        ({ slug, quantity }) => ({
          package_id: slug,
          quantity,
          ...(isModalTopup.value
            ? { iccid: buyEsimStore?.shoppingBagOptions?.topupEsimIccid }
            : {}),
        })
      ),
      payment_details: {
        method: paymentMethod.value.method,
        card_id:
          paymentMethod.value.method === 'stripe_card'
            ? paymentMethod.value.cardId
            : undefined,
      },
    })
    const orderId = response?.data?.id
    orderCode.value = response?.data?.code

    if (!orderCode.value) {
      placeOrderButtonLoading.value = false
      toast.negative({
        content: t('buyEsimPaymentFailMessage'),
      })
      return
    }

    const responseOrderDetails = await axios?.post(
      `/store/v1/orders/${orderId}/payment`
    )

    if (
      paymentMethod.value.method === 'stripe_card' &&
      responseOrderDetails?.data.status !== 'authorized'
    ) {
      const stripePaymentResponse = await stripe?.confirmCardPayment(
        responseOrderDetails?.data.provider_payload.client_secret,
        {
          payment_method: paymentMethod.value.stripeCardId,
        }
      )

      if (!stripePaymentResponse?.paymentIntent) {
        placeOrderButtonLoading.value = false
        if (
          stripePaymentResponse?.error &&
          stripePaymentResponse?.error.type === 'card_error' &&
          stripePaymentResponse?.error.message
        ) {
          const error = stripePaymentResponse.error.message
          toast.negative({
            content: t('buyEsimPaymentFailStripeMessage', {
              reason: error[0].toLowerCase() + error.slice(1),
            }),
          })
        } else {
          toast.negative({
            content: t('buyEsimPaymentFailMessage'),
          })
        }
        return
      }
    }

    if (buyEsimStore.shoppingBagOptions.isTopup) {
      buyEsimStore.topupShoppingBag = []
    } else {
      const newDestinations = getDestinationsForShoppingCart()
      const newRecentDestinations = [
        ...(newDestinations as RecentlyVisitedItem[]),
        ...recentlyVisited.value,
      ]

      recentlyVisited.value = newRecentDestinations
        .filter(
          (destination, index) =>
            newRecentDestinations.findIndex(
              (newDestination) => newDestination?.slug === destination?.slug
            ) === index
        )
        .slice(0, 10)

      buyEsimStore.clearShoppingBag()
    }

    showOrderSuccessfulModal.value = true
    placeOrderButtonLoading.value = false
    authStore.initialize()
  } catch (err) {
    console.error(err)

    placeOrderButtonLoading.value = false
    toast.negative({
      content: t('buyEsimPaymentFailMessage'),
    })
  }
}

const handlePaymentSuccessModalClose = () => {
  showOrderSuccessfulModal.value = false
  router.push('/esims-store')
}

const haveInsufficientFunds = ref(false)
const placeOrderDisabled = computed(
  () =>
    !!creditCardAmountCheck.value ||
    !paymentMethod.value ||
    !shoppingCart?.value.length ||
    orderCalculationLoading.value ||
    !!orderCalculationError.value ||
    (paymentMethod.value.method === 'airalo_credits' &&
      haveInsufficientFunds.value)
)

watch(shoppingCart, handleFetchOrderCalculation, { deep: true })

const zAmountSchema = z.object({
  amount: z
    .number()
    .min(1, { message: t('checkoutError.amountLowerThanMinimum') }),
})

const creditCardAmountCheck = computed(() => {
  if (
    !orderCalculation.value ||
    paymentMethod.value?.method !== 'stripe_card'
  ) {
    return null
  }

  const { success, error } = zAmountSchema.safeParse(
    authStore.hasResellerStream
      ? orderCalculation.value.net_price
      : orderCalculation.value.total
  )

  if (!success) {
    const groupedErrors: Record<string, z.ZodIssue[]> = groupBy(
      error.issues,
      'path'
    )

    return get(groupedErrors, `${'amount'.replaceAll('.', ',')}.0.message`)
  }

  return null
})

onMounted(() => {
  handleFetchOrderCalculation()
})

onBeforeRouteLeave((to) => {
  if (to.name !== 'esim-store') {
    buyEsimStore.topupShoppingBag = []
    buyEsimStore.resetShoppingBagOptions()
  }
})
</script>
