import { defineStore } from "pinia"
import { ref } from "vue"
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

type RouteData = {
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
}
export const useConfirmNavigationStore = defineStore('confirm-navigation', () => {
  const requiresLeaveConfirmation = ref(false)
  const isLeaving = ref(false)
  const pendingRoute = ref<RouteData | null>(null)

  function setRequiresLeaveConfirmation(payload: boolean) {
    requiresLeaveConfirmation.value = payload
  }

  function setIsLeaving(payload: boolean) {
    isLeaving.value = payload
  }

  function setPendingRoute(payload: RouteData) {
    pendingRoute.value = payload
  }

  function confirmNavigation() {
    if (pendingRoute.value) {
      pendingRoute.value.next()
      resetNavigationState()
    }
  }
  
  function cancelNavigation() {
    resetNavigationState()
  }
  
  function resetNavigationState() {
    isLeaving.value = false
    pendingRoute.value = null
  }

  return {
    setRequiresLeaveConfirmation,
    setIsLeaving,
    setPendingRoute,
    confirmNavigation,
    cancelNavigation,
    requiresLeaveConfirmation,
    isLeaving,
    pendingRoute,
  }
})