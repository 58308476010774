<template>
  <main
    class="relative bg-trail-background-primary w-full min-h-[calc(100%_-_93px)] z-[999]"
    data-testid="app-wrapper"
  >
    <div>
      <TopNavigationMobile v-if="isMobile" data-testid="base-header-mobile" />

      <TopNavigation v-else data-testid="base-header" />
    </div>

    <Transition name="mobilemenu">
      <SubNavigation
        v-if="isDesktop || userInterfaceStore.sidebarOpened"
        class="w-64 py-6 px-4 overflow-y-auto bg-trail-background-primary h-[calc(100%-60px)] shadow-trail-background-primary"
        :class="{
          'w-full mt-15 pb-20': isMobile,
          'h-[calc(100%-85px)]': isTablet,
          'h-[calc(100%-160px)]': isDesktop,
        }"
        data-testid="base-sidebar"
      />
    </Transition>

    <div
      class="transition-all duration-150 z-20"
      :class="isDesktop ? 'ltr:ml-64 rtl:mr-64' : ''"
      data-testid="content-wrapper"
    >
      <div
        class="lg:p-8 md:p-6 p-4 gap-5 z-[0] bg-trail-background-primary"
        :class="[
          !isMobile ? '!pb-16 mt-[93px]' : 'mt-16',
          isTablet && userInterfaceStore.sidebarOpened
            ? 'blur-sm brightness-75 pointer-events-none select-none h-screen !z-0'
            : '',
          !buyEsimStore?.isShoppingBagEmpty &&
          !authStore?.isOnlyAPIPartnerStream &&
          !hideShoppingBagBar
            ? '!pt-0'
            : '',
        ]"
        data-testid="page-content"
      >
        <ShoppingBagBar
          v-if="
            !buyEsimStore?.isShoppingBagEmpty &&
            !authStore?.isOnlyAPIPartnerStream &&
            !hideShoppingBagBar
          "
          class="mb-4"
          data-testid="shopping-bag-bar"
        />

        <div
          class="col-span-20 transition-all duration-150 container-fluid"
          data-testid="page-container"
        >
          <Breadcrumbs v-if="!$route.meta.hide" data-testid="breadcrumbs" />

          <router-view v-slot="{ Component }">
            <div v-if="Component">
              <transition name="router-animation" mode="out-in" appear>
                <component :is="Component"></component>
              </transition>
            </div>
          </router-view>
        </div>

        <NavigationFooter
          v-if="isMobile && !userInterfaceStore.sidebarOpened"
          class="w-full z-50"
          data-testid="base-footer"
        />
      </div>
    </div>

    <DialogModal data-testid="dialog-modal" />

    <NavigationFooter
      v-if="!isMobile && height > 700"
      class="w-full fixed bottom-0 z-50 bg-trail-background-primary"
      data-testid="base-footer"
    />
  </main>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { definePage, useRoute } from 'vue-router/auto'
import { useBuyEsimStore } from '@/stores/buy-esim.store'
import { useInterfaceStore } from '@/stores/userInterface'
import { useToastNotifications } from '@/composables/toastNotifications'
import { useAuthStore } from '@/stores/auth'
import { useResponsiveness } from '@/composables/responsiveness'
import { formatAmount } from '@/utils/formatAmount'
import { useToast } from '@/composables/useToast'
import type { MessageType } from '@/api/types/ablyTypes.ts'
import { useWindowSize } from '@vueuse/core'

const { height } = useWindowSize()
definePage({
  meta: { requiresAuth: true },
})

const route = useRoute()
const authStore = useAuthStore()
const userInterfaceStore = useInterfaceStore()
const buyEsimStore = useBuyEsimStore()
const { orderSuccessToast, orderCancelToast } = useToastNotifications()
const { t } = useI18n({ useScope: 'global' })
const { isMobile, isTablet, isDesktop } = useResponsiveness()
const toast = useToast()

const subscribeAbly = async () => {
  const channel = await authStore.ablyChannel

  channel.subscribe('order.status.changed', (message: MessageType) => {
    // TODO: Move to an util (maybe composable?), check other status cases
    switch (message.data.status) {
      case 'COMPLETED': {
        orderSuccessToast(t('orderStatusCompletedNotification'))
        break
      }

      case 'CANCELLED': {
        orderCancelToast(t('orderStatusCancelledNotification'))
        break
      }
    }
  })

  channel.subscribe(
    'finance.transaction.status.changed',
    function (message: MessageType) {
      const { reference_id_short, amount, status } = message.data
      if (status === 'completed' && amount?.amount > 0 && reference_id_short) {
        orderSuccessToast(
          t('creditStatusCompletedNotification', {
            amount: formatAmount(amount),
            referenceId: reference_id_short,
          })
        )
      }
    }
  )

  channel.subscribe(
    'sims.share_by_email.status.changed',
    (message: MessageType) => {
      if (message.data.status === 'ERROR') {
        toast.error(t('shareModalEmailError'), {
          timeout: 4000,
        })
      }
    }
  )
}

const hideShoppingBagBar = computed(() =>
  ['esim-store', 'esim-store-checkout'].includes(route.name)
)

onMounted(async () => {
  await subscribeAbly()
})
</script>
