export const DEFAULT_COLORS = {
  gray: {
    '0': '#ffffff',
    '50': '#f9fafb',
    '100': '#f3f4f6',
    '200': '#e5e7eb',
    '300': '#d1d5db',
    '400': '#9ca3af',
    '500': '#6b7280',
    '600': '#4b5563',
    '700': '#374151',
    '800': '#1f2a37',
    '900': '#111928',
    '1000': '#000000',
  },
  blue: {
    '50': '#ebf5ff',
    '100': '#e1effe',
    '200': '#c3ddfd',
    '300': '#a4cafe',
    '400': '#76a9fa',
    '500': '#3f83f8',
    '600': '#1c64f2',
    '700': '#1a56db',
    '800': '#1e429f',
    '900': '#233876',
  },
  red: {
    '50': '#fdf2f2',
    '100': '#fde8e8',
    '200': '#fbd5d5',
    '300': '#f8b4b4',
    '400': '#f98080',
    '500': '#f05252',
    '600': '#e02424',
    '700': '#c81e1e',
    '800': '#9b1c1c',
    '900': '#771d1d',
  },
  orange: {
    '50': '#fff8f1',
    '100': '#feecdc',
    '200': '#fcd9bd',
    '300': '#fdba8c',
    '400': '#ff8a4c',
    '500': '#ff5a1f',
    '600': '#d03801',
    '700': '#b43403',
    '800': '#8a2c0d',
    '900': '#771d1d',
  },
  yellow: {
    '50': '#fdfdea',
    '100': '#fdf6b2',
    '200': '#fce96a',
    '300': '#faca15',
    '400': '#e3a008',
    '500': '#c27803',
    '600': '#9f580a',
    '700': '#8e4b10',
    '800': '#723b13',
    '900': '#633112',
  },
  green: {
    '50': '#f3faf7',
    '100': '#def7ec',
    '200': '#bcf0da',
    '300': '#84e1bc',
    '400': '#31c48d',
    '500': '#0e9f6e',
    '600': '#057a55',
    '700': '#046c4e',
    '800': '#03543f',
    '900': '#014737',
  },
  teal: {
    '50': '#edfafa',
    '100': '#d5f5f6',
    '200': '#afecef',
    '300': '#7edce2',
    '400': '#16bdca',
    '500': '#0694a2',
    '600': '#047481',
    '700': '#036672',
    '800': '#05505c',
    '900': '#014451',
  },
  indigo: {
    '50': '#f0f5ff',
    '100': '#e5edff',
    '200': '#cddbfe',
    '300': '#b4c6fc',
    '400': '#8da2fb',
    '500': '#6875f5',
    '600': '#5850ec',
    '700': '#5145cd',
    '800': '#42389d',
    '900': '#362f78',
  },
  purple: {
    '50': '#f6f5ff',
    '100': '#edebfe',
    '200': '#dcd7fe',
    '300': '#cabffd',
    '400': '#ac94fa',
    '500': '#9061f9',
    '600': '#7e3af2',
    '700': '#6c2bd9',
    '800': '#5521b5',
    '900': '#4a1d96',
  },
  pink: {
    '50': '#fdf2f8',
    '100': '#fce8f3',
    '200': '#fad1e8',
    '300': '#f8b4d9',
    '400': '#f17eb8',
    '500': '#e74694',
    '600': '#d61f69',
    '700': '#bf125d',
    '800': '#99154b',
    '900': '#751a3d',
  },
}

export const SEMANTIC_COLORS = {
  background: { primary: '#f3f4f6', secondary: '#ffffff' },
  surface: {
    primary: {
      normal: '#e5e7eb',
      positive: '#046c4e',
      negative: '#c81e1e',
      warning: '#b43403',
      highlight: '#1a56db',
      in_progress: '#6c2bd9',
      on_dark: '#ffffff',
      disabled: '#d1d5db',
    },
    secondary: {
      normal: '#f3f4f6',
      positive: '#057a55',
      negative: '#e02424',
      warning: '#d03801',
      highlight: '#1c64f2',
      in_progress: '#7e3af2',
      on_dark: '#ffffff',
      disabled: '#f3f4f6',
    },
    tertiary: {
      normal: '#f9fafb',
      positive: '#def7ec',
      negative: '#fde8e8',
      warning: '#fdf6b2',
      highlight: '#e1effe',
      in_progress: '#edebfe',
      on_dark: '#ffffff',
      disabled: '#f9fafb',
    },
  },
  text: {
    display: { normal: '#111928', on_dark: '#f9fafb', disabled: '#d1d5db' },
    title: {
      normal: '#111928',
      positive: '#046c4e',
      negative: '#c81e1e',
      warning: '#b43403',
      highlight: '#1a56db',
      in_progress: '#6c2bd9',
      on_dark: '#f9fafb',
      disabled: '#6b7280',
    },
    body: {
      normal: '#374151',
      positive: '#046c4e',
      negative: '#c81e1e',
      warning: '#b43403',
      highlight: '#1a56db',
      in_progress: '#6c2bd9',
      on_dark: '#f9fafb',
      disabled: '#6b7280',
    },
    caption: {
      normal: '#374151',
      positive: '#046c4e',
      negative: '#c81e1e',
      warning: '#b43403',
      highlight: '#1a56db',
      in_progress: '#6c2bd9',
      on_dark: '#f9fafb',
      disabled: '#6b7280',
    },
  },
  icon: {
    normal: '#111928',
    positive: '#046c4e',
    negative: '#c81e1e',
    warning: '#b43403',
    highlight: '#1a56db',
    in_progress: '#6c2bd9',
    on_dark: '#f9fafb',
    disabled: '#d1d5db',
  },
  border: {
    normal: '#d1d5db',
    positive: '#046c4e',
    negative: '#c81e1e',
    warning: '#b43403',
    highlight: '#1a56db',
    in_progress: '#6c2bd9',
    on_dark: '#f9fafb',
    disabled: '#d1d5db',
  },
}
