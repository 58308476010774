import { ref, computed, inject } from 'vue'
import { useRouter } from 'vue-router/auto'
import { defineStore } from 'pinia'
import { removeAppLoading } from '@/utils/auth'
import { axios } from '@/http'
import * as Sentry from '@sentry/vue'
import { useFeatureFlagsStore } from '@/stores/feature-flags'

/**
 * @typedef {import('@/models/currencyModels').AmountType} AmountType
 */

export const useAuthStore = defineStore('auth', () => {
  const ably = inject('ably')
  const isInitialized = ref(false)
  const isInitStarted = ref(false)
  const userAuthenticated = useStorage('authenticated', false)
  /**
   * @type {any}
   */
  const user = ref(null)
  const router = useRouter()
  const isDataFetched = ref(false)

  let ablyChannelPromiseResolve

  const ablyChannel = ref(
    new Promise((resolve) => {
      ablyChannelPromiseResolve = resolve
    })
  )

  const hasUser = computed(() => !!user?.value)
  const isInitializedComp = computed(() => isInitialized.value)
  const isAuthenticated = computed(() => userAuthenticated.value)

  async function initialize() {
    if (
      (!isInitStarted.value || isInitialized.value) &&
      userAuthenticated.value
    ) {
      const featureFlagsStore = useFeatureFlagsStore()
      isInitStarted.value = true

      await featureFlagsStore.initFeatureFlags()
      await getAndSetUser()
    }
  }

  async function getAndSetUser() {
    try {
      const { realtime } = ably?.value ?? {}
      const { data } = await axios.get('/auth/v1/user')

      setUser(data)

      isInitialized.value = true
      userAuthenticated.value = true

      ablyChannel.value = realtime?.channels?.get(
        `private:hub.user.${user.value.id}`
      )

      return data
    } catch (err) {
      console.log(err)
    } finally {
      ablyChannelPromiseResolve()
    }
  }

  function setAblyChannel(payload) {
    ablyChannel.value = payload
  }

  function setUserAuthenticaitonStatus(status = true) {
    userAuthenticated.value = status

    return userAuthenticated.value
  }

  function setUser(payload) {
    user.value = payload
    isDataFetched.value = true

    if (user.value) {
      Sentry.setUser({
        id: user.value?.id,
        username: user.value?.first_name,
        company: user.value?.company,
        company_id: user.value?.company_id,
        email: user.value?.email,
      })
    }

    removeAppLoading()
  }

  const userFullName = computed(() => {
    const { first_name, last_name } = user?.value ?? {}

    return `${first_name ?? ''} ${last_name ?? ''}`
  })

  async function logout() {
    setUser(null)
    setUserAuthenticaitonStatus(false)

    ablyChannel.value = new Promise((resolve) => {
      ablyChannelPromiseResolve.value = resolve
    })

    if (Sentry.configureScope) {
      Sentry.configureScope((scope) => scope.setUser(null))
    }

    await router.push({ name: 'sign-in' })
  }

  const streamSlugList = computed(() => {
    return user.value?.streams?.map((stream) => stream.slug)
  })

  const hasAPIPartnerStream = computed(() => {
    return user.value?.streams?.some((stream) => stream.slug === 'api_partner')
  })

  const hasCorporateStream = computed(() => {
    return user.value?.streams?.some((stream) => stream.slug === 'corporate')
  })

  const hasResellerStream = computed(() => {
    return user.value?.streams?.some((stream) => stream.slug === 'reseller')
  })

  const isOnlyAPIPartnerStream = computed(() => {
    return user.value?.streams?.every((stream) => stream.slug === 'api_partner')
  })

  const isOnlyCorporateStream = computed(() => {
    return user.value?.streams?.every((stream) => stream.slug === 'corporate')
  })

  const hasResellerOrApiPartnerStream = computed(() => {
    return user.value?.streams?.some((stream) =>
      ['api_partner', 'reseller'].includes(stream.slug)
    )
  })

  const defaultAiraloCreditCardSettings = computed(() => {
    let defaultCardValue = null

    user.value?.settings?.forEach((item) => {
      if (item.name === 'default_credit_purchase_payment_method') {
        defaultCardValue = JSON.parse(item.value)
      }
    })

    return defaultCardValue
  })

  /**
   * @type {{
   *   method: 'stripe_card' | 'airalo_credits'
   *   card_id?: number
   * }}
   */
  const defaultBuyEsimCardSettings = computed(() => {
    let defaultCardValue = null

    user.value?.settings?.forEach((item) => {
      if (item.name === 'default_order_payment_method') {
        defaultCardValue = JSON.parse(item.value)
      }
    })

    return defaultCardValue
  })

  /**
   * @type {AmountType['currency']}
   */
  const userCurrency = computed(() => {
    return user.value?.currency ?? { symbol: '$', iso: 'USD' }
  })

  return {
    user,
    userCurrency,
    ablyChannel,
    hasUser,
    isAuthenticated,
    setAblyChannel,
    setUser,
    userFullName,
    logout,
    initialize,
    getAndSetUser,
    setUserAuthenticaitonStatus,
    streamSlugList,
    hasAPIPartnerStream,
    hasCorporateStream,
    hasResellerStream,
    isOnlyAPIPartnerStream,
    isOnlyCorporateStream,
    hasResellerOrApiPartnerStream,
    isInitializedComp,
    defaultAiraloCreditCardSettings,
    defaultBuyEsimCardSettings,
  }
})
