<template>
  <div class="flex flex-col gap-8">
    <h3 class="text-trail-title1 text-trail-text-title-normal">
      {{ $t('billingPageTitle') }}
    </h3>

    <BillingTable />
  </div>
</template>

<script setup lang="ts">
import { definePage } from 'vue-router/auto'

definePage({
  name: 'billing',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.finance.invoice.list'],
  },
})
</script>
