<template>
  <div class="grid">
    <OrderPageHeader
      data-testid="apiOrdersPageHeader"
      :orderPageTitle="t('apiOrderPageTitle')"
      :filterOpened="filterOpened"
      :orderListParams="orderListParams"
      :filterOptions="apiFilterOptions"
      :filterBadgeCount="filterBadgeCount"
      filterType="api"
      @dateSelected="selectDateOnClose"
      @clearSelectedDate="clearSelectDate"
      @searchFilterChange="searchFilter"
      @applyFilters="applyFilters"
      @updateFilterBadgeCount="updateFilterBadgeCount"
      @changeFilterOpened="changeFilterOpened"
    />

    <OrderTable
      data-testid="apiOrdersTable"
      :rows="orderList"
      :is-loading="tableLoading"
      :pagination="paginationInfo"
      :is-searched="isSearched"
      :columns="columns"
      order-type="api"
      @pagination-changed="pageChanged"
    />
  </div>
</template>

<script setup>
import { inject, ref, onMounted, watch } from 'vue'
import { definePage } from 'vue-router/auto'
import { useI18n } from 'vue-i18n'
import { apiFilterOptions, apiOrderStatuses } from '@/constant/orders'
import { useAuthStore } from '@/stores/auth'
import 'flatpickr/dist/flatpickr.css'

definePage({
  name: 'api-orders',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Package\\Models\\Order'],
  },
})

const authStore = useAuthStore()
const { t } = useI18n({ useScope: 'global' })

const tableLoading = ref(false)
const axios = inject('axios')
const rangeDate = ref(null)
const filterOpened = ref(false)
const orderList = ref([])
const paginationInfo = ref({})
const isSearched = ref(false)
const filterBadgeCount = ref(0)
const getOrderAbortController = ref(null)

const orderListParams = ref({
  selectDate: '',
  filterOption: '',
  search: '',
  page: 'page=1',
})

const columns = ref([
  {
    name: t('orderTableOrderId'),
    key: 'code',
    customHeaderClass: 'w-52',
  },

  {
    name: t('orderTableDate'),
    key: 'created_at',
    customHeaderClass: 'w-72',
  },

  {
    name: t('orderTableQuantity'),
    key: 'quantity',
    customHeaderClass: 'w-36 text-right',
    customClass: 'flex justify-end',
  },

  {
    name: t('apiOrderTableTotalNetPrice'),
    key: 'net_price',
    customHeaderClass: 'w-40 text-right',
    customClass:
      'flex justify-end whitespace-nowrap text-ultra-marine-blue-600',
  },

  {
    name: t('apiOrderTableTotalRetailPrice'),
    key: 'total_price',
    customHeaderClass: 'w-40 text-right',
    customClass: 'flex justify-end whitespace-nowrap',
  },

  {
    name: t('orderTableStatus'),
    key: 'status',
    customHeaderClass: 'w-60',
  },
])

onMounted(() => {
  getOrderList()
})

const getOrderList = async (clearPage = false) => {
  if (getOrderAbortController.value) {
    getOrderAbortController.value.abort()
  }

  getOrderAbortController.value = new AbortController()

  tableLoading.value = true
  let params = '?limit=25'

  if (clearPage) {
    orderListParams.value['page'] = `page=1`
  }

  Object.keys(orderListParams.value).forEach((item) => {
    if (orderListParams.value[item]) {
      params += `&${orderListParams.value[item]}`
    }
  })

  params = params.replace('&&', '&')

  if (axios) {
    const { data } = await axios
      .get(`/public-api/v1/orders${params}`, {
        signal: getOrderAbortController?.value?.signal,
      })
      .finally(() => {
        tableLoading.value = false
      })

    orderList.value = data.data.map((order) => ({
      ...order,
      status: apiOrderStatuses.find(
        (status) => status.slug == order.status.slug
      ).value,
      esim_count: order.type == 'sim' ? order.quantity : 0,
      topup_count: order.type == 'topup' ? order.quantity : 0,
    }))

    paginationInfo.value = data.pagination_info
  }

  isSearched.value = !!orderListParams?.value?.search?.length
}

const pageChanged = async (page) => {
  orderListParams.value['page'] = `page=${page}`

  await getOrderList()
}

const changeFilterOpened = (filterStatus) => {
  filterOpened.value = filterStatus
}

const selectDateOnClose = (selectedDates, dateStr) => {
  let params = ''
  let dates = dateStr.split('to').map((item) => item.trim())

  if (!dates[0]) return

  dates.forEach((date, index) => {
    if (dates.length === 1) {
      params = `filter[created_at]=${date} - ${date}`
    } else if (index === 0) {
      params = `filter[created_at]=${date}`
    } else {
      params += ` - ${date}`
    }
  })

  if (orderListParams.value.selectDate === params) return

  orderListParams.value.selectDate = params
  getOrderList(true)
}

const clearSelectDate = () => {
  rangeDate.value = null
  orderListParams.value.selectDate = ''
  getOrderList(true)
}

const searchFilter = (searchString) => {
  if (orderListParams.value['search'] !== `filter[code]=${searchString}`) {
    orderListParams.value['search'] = searchString
      ? `filter[code]=${searchString}`
      : ''

    getOrderList(true)
  }
}

const updateFilterBadgeCount = (badgeCount) => {
  filterBadgeCount.value = badgeCount
}

const applyFilters = async (params) => {
  filterOpened.value = false

  orderListParams.value['filterOption'] = params

  await getOrderList(true)
}

watch(
  () => authStore.userCurrency,
  () => {
    getOrderList()
  }
)
</script>
